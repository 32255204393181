import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {LoginResponse} from '../models/auth';
import {Notifications} from '../models/common';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient) {
    }

    validateToken(token) {
        return this.http.post<LoginResponse>('validateToken', token)
            .pipe(map((data) => new LoginResponse(data)));
    }

    currentUserInfo() {
        return this.http.get<LoginResponse>('current-user')
            .pipe(map((data) => new LoginResponse(data)));
    }

    currentUserInfo1() {
        return this.http.get<LoginResponse>('current-user')
            .pipe(map((data) => data));
    }

    refreshToken() {
        const refreshToken = localStorage.getItem('refreshToken');
        const idToken = localStorage.getItem('idToken');
        const body = {refreshToken, idToken};
        if (refreshToken && idToken) {
            return this.http.post<LoginResponse>('refresh', body)
                .pipe(map((data) => new LoginResponse(data)));
        }
    }

    changeStatus(state) {
        return this.http.post(`update-active/${state}`, {})
            .pipe(map((data) => data));
    }

    changeDriverStatus(state) {
        return this.http.put(`updateDriverReq`, state)
            .pipe(map((data) => data));
    }

    updateUserLanguage(lang) { // -------------Update preferred language ---------------
        console.log(lang)
        return this.http.post(`update-preferred-language`, {language:lang})
          .pipe(map(data => data));
      }

    deleteAccount() {
        return this.http.delete(`/delete-user`)
            .pipe(map((data) => data));
    }

    getUserNotifications(page) {
        return this.http.get(`notifications`, {params: {limit: '200', page}})
            .pipe(map((data) => data));
    }
    viewedNotification() {
        return this.http.post<Notifications>(`read-notification`, {})
            .pipe(map((data) => data));
    }

    refreshTokenFetch(){
        const refreshToken = localStorage.getItem('refreshToken');
        const idToken = localStorage.getItem('idToken');
        const body = {refreshToken, idToken};
        if (refreshToken && idToken) {
            return this.http.post('refresh', body);
        }
    }

    logout() {
        return this.http.post('logout', null);
    }
}
