import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms'

@Injectable()
export class FormService {

    constructor(
        public builder: FormBuilder,
    ) { }

    atLeastOne = (validator: ValidatorFn) => (
        group: FormGroup,
    ): ValidationErrors | null => {
        const hasAtLeastOne = group && group.controls && Object.keys(group.controls)
            .some(k => !validator(group.controls[k]));

        return hasAtLeastOne ? null : {
            atLeastOne: true,
        }
    };

    error(form: FormGroup, name: string, type = 'required') {
        return (form.get(name).errors || {})[type]
    }

    groupError(form: FormGroup, type = 'required') {
        return (form.errors || {})[type]
    }

}
