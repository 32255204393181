import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

@Injectable()
export class ErrorHandlerService {
    public errorMessage = '';

    constructor(
        private router: Router,
        private auth: AuthService
    ) {
    }

    public handleError(error: HttpErrorResponse) {
        if (error.status === 500) {
            this.handle500Error(error);
        } else if (error.status === 401) {
            this.handle401Error(error);
        } else if (error.status === 404) {
            this.handle404Error(error);
        } else {
            this.handleOtherError(error);
        }
    }

    private handle500Error(error: HttpErrorResponse) {
        this.createErrorMessage(error);
        this.router.navigate(['/public/server-error']);
    }

    private handle404Error(error: HttpErrorResponse) {
        this.createErrorMessage(error);
        this.router.navigate(['/public/maintenance']);
    }

    private handle401Error(error: HttpErrorResponse) {
        this.createErrorMessage(error);
        if (error.error.msg !== 'Old Password is incorrect.' && error.error.msg !== 'Invalid ID token provided') {
            if (localStorage.getItem('accessToken') && localStorage.getItem('idToken')){
                this.auth.refreshToken().subscribe(opts => {
                    localStorage.setItem('user', JSON.stringify(opts.user));
                    localStorage.setItem('accessToken', opts.accessToken);
                    localStorage.setItem('idToken', opts.idToken);
                    // window.location.reload();
                }, (err) => {
                    console.log('Refresh token error : ', err);
                    localStorage.clear();
                    // this.router.navigate(['/login']);
                });
            }
        } else if (error.error.msg === 'Invalid ID token provided') {
            console.log('Refresh token error')
            localStorage.clear();
            // this.router.navigate(['/login']);
        }
    }

    private handleOtherError(error: HttpErrorResponse) {
        this.createErrorMessage(error);
        // TODO: this will be fixed later;
    }

    private createErrorMessage(error: HttpErrorResponse) {
        this.errorMessage = error.error ? error.error : error.message;
    }

}

